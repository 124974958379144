export const Users = [

    {
        id: 1,
        email: "mohitsuri@gmail.com",
        divisionName: "Cake Division",
        incharge: "Mohit Suri",
        totalEmployee: 98,

    },

    {
        id: 2,
        email: "shuklashakti@gmail.com",
        divisionName: "Cake Division",
        incharge: "Shakti Shukla",
        totalEmployee: 9,


    },

    {
        id: 3,
        email: "ankit721@gmail.com",
        divisionName: "Cake Division",
        incharge: "Ankit Sharma",
        totalEmployee: 74,

    },
    {
        id: 4,
        email: "shivamagarwal@gmail.com",
        divisionName: "Cake Division",
        incharge: "Shivam Agarwal",
        totalEmployee: 69,


    },
    {
        id: 5,
        email: "saxena@gmail.com",
        divisionName: "Cake Division",
        incharge: "Sourabh Saxena",
        totalEmployee: 52,


    },






]